import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { globalHistory as history } from '@reach/router'
import '../../assets/sass/styles.sass'
import useSiteMetadata from '../SiteMetadata'
import NavBar from '../NavBar'
import Footer from '../Footer'

const Layout = ({ children }) => {
  const [navBar, toggleNavBar] = useState(false)
  const { siteUrl, title, description, image, imgAlt } = useSiteMetadata()
  const { location } = history

  let currentPath = ''
  let currentOrigin = siteUrl
  try {
    if (location.href) {
      currentPath = location.href
    }
    if (location.origin) {
      currentOrigin = location.origin
    }
  } catch (e) {}
  return (
    <div id='layout-wrapper'>
      <Helmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={description} />
        <link rel='canonical' href={currentPath} />
        <meta name='theme-color' content='#fff' />
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='Schoolhouse by the Sea' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={`${currentOrigin}${image}`} />
        <meta property='og:url' content={currentPath} />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:image' content={`${currentOrigin}${image}`} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:image:alt' content={imgAlt} />
      </Helmet>
      <NavBar isActive={navBar} toggleNavbar={() => toggleNavBar(!navBar)} />
      <div id='content-wrapper'>
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
