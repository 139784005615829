module.exports = {
  siteTitle: 'Metz Tech', // Site title.
  siteTitleAlt: 'Metz Tech', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://metztech.co', // Domain of your website without pathPrefix.
  siteImage: '/img/og-image.png',
  siteImgAlt: 'Metz Tech',
  pathPrefix: '', // Prefixes all links.
  siteDescription: 'Metz Tech is a technology design agency.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  userName: 'Metz-Tech',
  userTwitter: '',
  userLocation: 'California, USA',
  userDescription: '',
  copyright: 'Copyright © Metz Tech Co. 2017-2020. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#ffffff', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
